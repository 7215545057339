<template>
	<div>
		<app-app-header v-model="indexStore.isHeaderVisible"
						:title="filterPageTitle ?? $t('label.club_map')"
						:color="$config.public.colors.secondary"
						:breadcrumbs="filterPageTitle ? [{to: $web('clubs.index'), text: $t('label.club_map')}] : undefined"
						:item-link="$auth.loggedIn ? {to: $web('my.memberships.index'),text: $t('label.my_memberships')} : undefined" hide-on-scroll/>
		<app-content>

			<app-header-toolbar :on-top="!indexStore.isHeaderVisible" :color="$config.public.colors.secondary"
								:item-link="$auth.loggedIn ? {to: $web('my.memberships.index'),text: $t('label.my_memberships')} : undefined">
				<form-input v-model="filterData.search"
							:icon="icons.search"
							type="search"
							@focus="handleSearchInputFocus"
							@blur="handleSearchInputBlur"
							enterkeyhint="search"
							name="search" :placeholder="$t('placeholder.search_club')"
							inverted hide-details clearable/>

				<template #tools>
					<chip v-if="filterExists" class="mr-8" small clickable @click="resetFilter()">
						<icon small center :icon="icons.reset"/>
					</chip>

					<chip-modal small
								:text="selectedSportsText ?? $t('placeholder.sport_type_id')"
								:title="$t('label.sport_type')"
								:color="selectedSportsText ? 'secondary' : null"
								@submit="handleSubmittedFilter('sport')"
								@open="handleOpenedFilter('sport')" class="mr-8" :max-width="200">
						<template v-slot="{deactivate, apply}">
							<cascader-input v-model="inputFilterData.sport"
											:options="sportTypes"
											:color="$config.public.colors.secondary"
											value-prop="_key"
											text-prop="title"
											filterable
											help-prop="description"
											:unselect="$t('label.any')"
											@selected="apply"/>
						</template>
					</chip-modal>

					<chip-modal small
								:text="selectedLocationText ?? $t('placeholder.region')"
								:title="$t('label.region')"
								:color="selectedLocationText ? 'secondary' : null"
								@submit="handleSubmittedFilter(['country', 'state'])"
								@open="handleOpenedFilter(['country', 'state'])" class="mr-8" :max-width="200">
						<template v-slot="{deactivate, apply}">
							<cascader-input v-model="selectedRegionInput"
											:options="countries"
											:color="$config.public.colors.secondary"
											value-prop="_key"
											text-prop="locale_name"
											image-prop="flag"
											children-prop="states"
											children-count-prop="states_count"
											:children-loader="loadCountryStates"
											:unselect="$t('label.any')"
											@selected="apply"/>
						</template>
					</chip-modal>

				</template>

			</app-header-toolbar>

			<page-content background :full="!showInRow">
				<container :full="!showInRow" class="stretch-layout">
					<div class="flex flex-row-reverse stretch-layout">
						<sticky-content :offset="192" :enabled="showInRow" class="stretch-layout pb-16">
							<map-map ref="mapElement"
									 center-visitor
									 :locate-button="showInRow"
									 :hide-controls="!showInRow"
									 :rounded="showInRow"
									 v-model:fullscreen="showMapFullScreen"
									 v-model:bounds="mapBounds"
									 v-model:zoom="mapZoom"
									 @click="focusMap"
									 @move="focusMap"
									 class="stretch-layout">

								<club-marker v-for="mapClub in mapModels" :club="mapClub" :key="'marker-'+mapClub.id" @click="showClubDetails(mapClub)"/>

								<map-marker v-for="(mapMarkerGroup, mapMarkerGroupIndex) in mapMarkerGroups" :text="mapMarkerGroup.amount"
											@click="focusMarkerGroup(mapMarkerGroup)"
											:lat-lng="[mapMarkerGroup.lat, mapMarkerGroup.lng]"
											:color="$config.public.colors.gray_300"
											no-arrow
											:key="mapMarkerGroupIndex"/>
							</map-map>
						</sticky-content>

						<responsive-floating-card
							v-model:floating-height="floatingListHeight"
							:floating-min-height="floatingListMinHeight"
							:floating-anchors="floatingListAnchors"
							card-transparent
							card-class="stretch-layout w-400 max-w-400 mr-16">
								<card-list-header
									id="clubs-index"
									:title="t('view.clubs.index.title')"
									:description="listHeaderDescription"
									:color="$config.public.colors.secondary"
									image="widgets/widget-clubs.png" :ratio="4" class="lg:rounded lg:mb-16 lg:shadow w-full"/>

								<data-loader :url="$api('clubs.index')"
											 as-guest
											 :params="params"
											 :persist-filter-keys="['sport', 'order', 'search', 'country', 'state']"
											 :filter="filterData"
											 on-scroll
											 ref="lazyLoad" @filtering="handleApplyingFilter">

									<template #loading>
										<div class="p-16 lg:p-0">
											<row tight>
												<column cols="6" sm="4" md="4" lg="6" v-for="clubLoader in 12" :key="'club-loader-' + clubLoader">
													<club-card-skeleton/>
												</column>
											</row>
										</div>
									</template>

									<template v-slot="{data: clubs}">
										<div class="p-16 lg:p-0">
											<row tight>
												<column cols="6" sm="4" md="4" lg="6" v-for="(club, clubKey) in clubs" :key="'club-card-' + clubKey">
													<club-card :to="$app('clubs-club', {params: {club: club._key}, state: {club_ts: $date(club.updated_at).unix()}})" :club="club" class="club-card"/>
												</column>
											</row>
										</div>
									</template>

									<template #missingsearch>
										<div class="p-16 lg:p-0">
											<lazy-missing-info :text="$t('missing.club.search')" card no-spacing vertical :to="$web('clubs.create')" :button="$t('action.club.create')"/>
										</div>
									</template>
									<template #missing>
										<div class="p-16 lg:p-0">
											<lazy-missing-info :text="$t('missing.club.index')" card no-spacing vertical :to="$web('clubs.create')" :button="$t('action.club.create')"/>
										</div>
									</template>

								</data-loader>
						</responsive-floating-card>
					</div>

				</container>
			</page-content>

			<slide-sheet v-model="isClubDetailsVisible" :width="240">
				<template #default>
					<club-card-skeleton v-if="clubDetailsPending" flat no-spacing :width="240"/>
					<club-card v-else-if="clubDetails" :club="clubDetails" flat no-spacing :width="240"/>
				</template>
				<template v-if="clubDetails" #footer>
					<btn :color="clubDetails.color" large text tile class="w-full border-t" :to="$app(`clubs-club`, {params: {club: clubDetails._key}, state: {club_ts: $date(clubDetails.updated_at).unix()}})">
						<span>{{ $t('action.show_profile') }}</span>
						<icon :icon="icons.next"/>
					</btn>
				</template>
			</slide-sheet>
		</app-content>
	</div>
</template>

<script setup lang="ts">
import type {Country, SportTypePreview} from "@spoferan/spoferan-ts-core";
import {backIcon, closeIcon, searchIcon, nextIcon, resetIcon} from "@spoferan/nuxt-spoferan/icons";
import {useIndexStore} from "../../store";

const indexStore = useIndexStore();
const {$api, $apiFetch, $web, $auth, $date} = useNuxtApp();
const config = useRuntimeConfig();
const {t} = useI18n();
const route = useRoute();

// The filter data that can be modified by the user
const filterData = ref({
	search: route.query.search,
	sport: route.query.sport,
	country: route.query.country,
	state: route.query.state,
});

const responses = await Promise.all([
	useApiFetch('/v1/sports/options', {
		guest: true,
		params: {
			with_clubs: true
		}
	}),
	useApiFetch('/v1/countries/options', {
		guest: true,
		params: {
			with_clubs: true
		}
	}),
	filterData.value.country ? useApiFetch(`/v1/countries/${filterData.value.country}/states/options`, {
		guest: true,
		params: {
			with_clubs: true
		}
	}) : Promise.resolve({data: []}),
]);

// Sport type filter
const sportTypes: SportTypePreview[] = responses[0].data;

// Region filter
const countries: Country[] = responses[1].data;
const initiallySelectedCountry = filterData.value.country ? countries.find(country => country._key === filterData.value.country) : null;
if (initiallySelectedCountry && responses[2].data.length) {
	initiallySelectedCountry.states = responses[2].data;
}

const presentationTypes = ['sports_club', 'fitness_club', 'friends_club'];

const {
	deviceCoordinates,
	mapModels,
	mapZoom,
	showInRow,
	inputFilterData,
	mapBounds,
	showMapFullScreen,
	mapMarkerGroups,
	filterExists,
	mapElement,
	floatingListHeight,
	floatingListAnchors,
	floatingListMinHeight,
	handleSearchInputFocus,
	handleSearchInputBlur,
	handleSubmittedFilter,
	handleOpenedFilter,
	resetFilter,
	focusMarkerGroup,
	handleApplyingFilter,
	refreshDeviceCoordinates,
	focusMap
} = useMapMainModelSearchPage($api('clubs.map'), filterData, {
	params: presentationTypes
});

const selectedRegionInput = ref([filterData.value.country, filterData.value.state]);
watch(selectedRegionInput, (region) => {
	inputFilterData.value.country = region[0];
	inputFilterData.value.state = region[1];
});

const selectedSportsText = computed(() => {
	const selectedSportType = filterData.value.sport ? sportTypes.find(sport => sport._key === filterData.value.sport) : null;
	if (!selectedSportType) {
		return null;
	}

	return selectedSportType.title;
});

const selectedLocationText = computed(() => {
	const selectedCountry = filterData.value.country ? countries.find(country => country._key === filterData.value.country) : null;
	if (!selectedCountry) {
		return null;
	}

	const selectedCountryState = filterData.value.state ? selectedCountry.states.find(countryState => countryState._key === filterData.value.state) : null;
	if (selectedCountryState) {
		return selectedCountryState.locale_name
	}

	return selectedCountry.locale_name;
});

const filterPageTitle = computed(() => {
	const parts = [selectedSportsText.value ? `${selectedSportsText.value}-${t('label.clubs')}` : (selectedLocationText.value ? t('label.clubs') : null), selectedLocationText.value].filter(value => !!value);

	return parts.length ? parts.join(` in `) : null;
});

const listHeaderDescription = computed(() => {
	if (selectedSportsText.value && selectedLocationText.value) {
		return t('view.clubs.index.description_location_sport', {sport: selectedSportsText.value, location: selectedLocationText.value});
	}
	if (selectedSportsText.value) {
		return t('view.clubs.index.description_sport', {sport: selectedSportsText.value});
	}
	if (selectedLocationText.value) {
		return t('view.clubs.index.description_location', {location: selectedLocationText.value});
	}

	return t('view.clubs.index.description')
});

const icons = {
	search: searchIcon,
	reset: resetIcon,
	back: backIcon,
	next: nextIcon,
	close: closeIcon
};

// The filter data to send with every request the user can not modify
const params = computed(() => {
	const params = {
		limit: 20,
		relations: ['sportTypes'],
		order: ['distance'],
		presentation_type: presentationTypes,
		lat: undefined as string | undefined,
		lng: undefined as string | undefined
	};

	const coordinates = {
		lat: deviceCoordinates.value?.lat ?? null,
		lng: deviceCoordinates.value?.lng ?? null,
	};

	// We round the decimal places to improve caching
	params.lat = coordinates.lat ? parseFloat(coordinates.lat).toFixed(1) : undefined;
	params.lng = coordinates.lng ? parseFloat(coordinates.lng).toFixed(1) : undefined;

	return params;
});

const isClubDetailsVisible = ref(false);
const clubDetails = ref(null);
const clubDetailsPending = ref(false);
async function showClubDetails(mapClub) {
	clubDetails.value = mapClub;
	clubDetailsPending.value = true;
	isClubDetailsVisible.value = true;

	$apiFetch($api('clubs.map.show', mapClub.id), {
		guest: true,
		params: {
			ts: $date(mapClub.updated_at).unix()
		}
	}).then(response => {
		clubDetails.value = response.data;
	}).finally(() => {
		clubDetailsPending.value = false;
	})
}

async function loadCountryStates(country) {
	if (!country || !country.states_count) {
		country.states = []
		return;
	}

	const {data} = await $apiFetch(`/v1/countries/${country._key}/states/options`, {
		guest: true,
		params: {
			with_clubs: true
		}
	});
	country.states = data
}

useMeta({
	title: t('view.clubs.index.title') + (filterPageTitle.value ? `: ${filterPageTitle.value}` : ''),
	description: listHeaderDescription.value,
	canonicalQueries: Object.keys(filterData.value)
});

useSchemaOrg([
	defineWebPage({
		'@type': ['CollectionPage', 'SearchResultsPage']
	}),
])

onMounted(() => {
	nextTick(() => {
		// We need to wait for the map to be mounted
		refreshDeviceCoordinates();
	})
})
</script>